import React from "react";
import { useState } from "react";

function FaqItem({ header, details }) {
  const [show, setShow] = useState(false);
  return (
    <div>
      <p
        onClick={() => setShow(!show)}
        className="font-['Montserrat'] text-justify font-bold text-[20px] leading-[36px] mb-[24px] cursor-pointer border rounded-[10px] p-4 shadow-md"
      >
        {header}
      </p>
      {show && details}
    </div>
  );
}

export default FaqItem;
