import React from "react";
import { useState, useEffect } from "react";

function SlideItem({ item }) {
  const { header, text, description } = item;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(!show), 5000);
  }, [show]);
  return (
    <div className="w-full justify-center items-center flex">
      <div className="mb-12 w-[80%] lg:w-[420px] h-[300px]">
        <div
          className="cursor-pointer rounded-t-[30px] bg-[#4A0251A8] h-[60px] flex justify-center items-center"
          onClick={() => setShow(!show)}
        >
          <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
            {header}
          </p>
        </div>
        <div
          className={`rounded-b-[30px] bg-[#451E48] h-[240px] ${
            header !== "Write it Out" && "px-4"
          } w-full flex justify-between items-center`}
        >
          <p
            className={`${
              show ? "block" : "hidden"
            } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
          >
            {text}
          </p>
          <div className={` ${show ? "hidden" : "block"} w-full`}>
            {description}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlideItem;
