import Christina from "../images/Christina.png";
import Scroll from "../images/Scroll.png";
import Woman from "../images/Woman.png";
import Rewind from "../images/Rewind.png";
import Play from "../images/Play.png";
import Fast from "../images/Fast.png";
import Mic from "../images/Mic.png";
import Diary from "../images/Diary.png";

export const Swipers = [
  {
    header: " Speak with a Therapist",
    text: "Be matched with a professional therapist that can attend to your specific needs. Whether you prefer video/phone calls, text or in-person chats, Sofa Therapy is accessible and affordable.",
    description: (
      <div className="w-full flex justify-between items-center">
        <div className={`transition-all duration-200 w-[161px]`}>
          <div className="mb-1 mt-8 w-[120px] h-[21px] bg-[#D42CE226] rounded-[10px] rounded-bl-[0px] flex justify-center items-center">
            <p className="font-extrabold font-['Montserrat'] text-[10px] text-center text-white tracking-[-0.015em]">
              Therapist Profile
            </p>
          </div>

          <img src={Christina} alt="" />
        </div>
        <div className={`transition-all duration-200  w-[184px]`}>
          <p className="font-extrabold font-['Montserrat'] text-[13px]  text-white leading-[21px]">
            Bio
          </p>
          <p className="font-normal font-['Montserrat'] text-[10px] text-white leading-[21px]">
            Brief bio about the therapist. Written by the therapist, of course.
          </p>
          <p className="mt-3 font-extrabold font-['Montserrat'] text-[10px] text-white leading-[21px]">
            Pick Session:
          </p>
          <div className="flex flex-row justify-between items-center">
            <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
              <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                Tue, 8am
              </p>
            </div>
            <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
              <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                Thu, 8am
              </p>
            </div>
            <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
              <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                Mon, 8am
              </p>
            </div>
          </div>
          <div className="h-[2px] w-full bg-[#FFFFFF69] drop-shadow-lg my-2 " />
          <div className="w-[154px] h-8 rounded-[40px] flex justify-center items-center align-center mx-auto bg-[#100521] opacity-30">
            <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
              Book therapist (₦20,000)
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    header: "Play. Pause. Shuffle. Repeat.",
    text: "Scroll through our playlist of exclusive songs, find what best suits your soul, relax in the rhythm and drown out the noise. Sofa Music is healing, one note, one beat, one sound at a time.",
    description: (
      <div className={`w-full`}>
        <div
          className={`transition-all duration-200 flex flex-row w-full justify-between items-end pt-6`}
        >
          <div className="">
            <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
              You are not alone
            </p>
            <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
              Ukpono Akpan
            </p>
          </div>
          <img src={Woman} alt="woman" />
        </div>
        <img
          src={Scroll}
          alt="scroll"
          className={`transition-all duration-200 my-2 w-full`}
        />
        <div
          className={`transition-all duration-200 flex justify-between items-center`}
        >
          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
            0:00
          </p>
          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
            2:35
          </p>
        </div>
        <div
          className={`flex justify-center items-center transition-all duration-200`}
        >
          <img src={Rewind} alt="Rewind" />
          <img src={Play} alt="Play" className="mx-[27px]" />
          <img src={Fast} alt="Fast" />
        </div>
      </div>
    ),
  },
  {
    header: "Tune into Truth",
    text: "Find relatability for your soul. plug into real conversations for some insight, transparency, encouragement, humour and warmth from our hosts & guests on the Sofa Talk.",
    description: (
      <div className="w-full">
        <div
          className={`flex flex-row w-full justify-between items-end pt-2 transition-all duration-200`}
        >
          <div className="">
            <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
              Podcast Name
            </p>
            <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
              Season 0, Episode 0
            </p>
          </div>
          <img src={Mic} alt="mic" />
        </div>
        <img
          src={Scroll}
          alt="scroll"
          className={`my-2 w-full transition-all duration-200`}
        />
        <div
          className={`flex justify-between items-center transition-all duration-200`}
        >
          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
            0:00
          </p>
          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
            7:05
          </p>
        </div>
        <div
          className={`transition-all duration-200 flex justify-center items-center`}
        >
          <img src={Rewind} alt="Rewind" />
          <img src={Play} alt="Play" className="mx-[27px]" />
          <img src={Fast} alt="Fast" />
        </div>
      </div>
    ),
  },
  {
    header: "Write it Out",
    text: "It gets real personal in your Sofa Diary, because you deserve to pour it out; anytime, anywhere. It’s just you and your words – write your heart out. It’s your safe place.",
    description: (
      <div className="w-full">
        <div
          className={`px-4 flex flex-row w-full justify-between items-end pt-2 transition-all duration-200 `}
        >
          <img src={Diary} alt="Diary" />
          <div className="mx-2">
            <p className="font-bold font-['Montserrat'] text-[17px] leading-[34px] text-center text-white">
              Welcome to SofaDiary
            </p>
            <div className="bg-[#826DAF66] h-[100px] rounded-[24px] py-[15px] px-2 items-center justify-center flex">
              <p className="font-medium font-['Montserrat'] text-[9px] leading-[21px] text-center text-white">
                You deserve to let it all out. No filters, no judgements. Just
                pour out your thoughts here and let these pages safekeep them.
              </p>
            </div>
          </div>
        </div>
        <div
          className={`transition-all duration-200 mt-5 h-[22px] w-full bg-[#72129F75]`}
        />
        <div
          className={`transition-all duration-200 h-[22px] w-full bg-[#956AA975] mt-[6px]`}
        />
      </div>
    ),
  },
];
