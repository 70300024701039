import { Link, Element } from "react-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import Sofa from "./images/SofaDef.png";
import FeatBG from "./images/FeatBG.png";
import ContactBG from "./images/ContactBG.png";
import Twitter from "./images/Twitter.png";
import Instagram from "./images/Instagram.png";

import { ReactComponent as Logo } from "./svg/logo.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import FaqItem from "./components/FaqItem";
import { faqData, faqData2 } from "./data/faq";
import { Swipers } from "./data/swipers";
import SlideItem from "./components/SlideItem";

function App() {
  // Get all the elements you want to show on scroll
  const targets = document.querySelectorAll(".js-show-on-scroll");

  // Callback for IntersectionObserver
  const callback = function (entries) {
    entries.forEach((entry) => {
      // Is the element in the viewport?
      if (entry.isIntersecting) {
        // Add the fadeIn class:
        entry.target.classList.add("motion-safe:animate-fadeIn");
      } else {
        // Otherwise remove the fadein class
        entry.target.classList.remove("motion-safe:animate-fadeIn");
      }
    });
  };

  // Set up a new observer
  const observer = new IntersectionObserver(callback);

  // Loop through each of the target
  targets.forEach(function (target) {
    // Hide the element
    target.classList.add("opacity-0");

    // Add the element to the watcher
    observer.observe(target);
  });
  return (
    <div className="w-full flex-1">
      <div className="min-h-[4vw] px-8 flex flex-row items-center w-full">
        <div className="my-4 mr-[40%]">
          <Logo />
        </div>
        <div className="h-[4vw] w-full hidden lg:flex flex-row items-center justify-between">
          <Link spy={true} smooth={true} duration={500} to="About">
            <p className="cursor-pointer font-['Montserrat'] text-[1rem] leading-[18px] text-center text-[#4A0251] font-medium">
              About Sofa
            </p>
          </Link>
          <Link spy={true} smooth={true} duration={500} to="Features">
            <p className="cursor-pointer font-['Montserrat'] text-[1rem] leading-[18px] text-center text-[#4A0251] font-medium">
              Features
            </p>
          </Link>
          <Link spy={true} smooth={true} duration={500} to="Join">
            <p className="cursor-pointer font-['Montserrat'] text-[1rem] leading-[18px] text-center text-[#4A0251] font-medium">
              Join Us
            </p>
          </Link>
          <Link spy={true} smooth={true} duration={500} to="FAQ">
            <p className="cursor-pointer font-['Montserrat'] text-[1rem] leading-[18px] text-center text-[#4A0251] font-medium">
              FAQs
            </p>
          </Link>
        </div>
      </div>
      <div className="min-w-screen px-8 min-h-[96vh] flex items-center mb-4 bg-[#CD6ED61D] md:bg-[url('./images/Back.png')] bg-right-bottom bg-origin-border bg-no-repeat bg-contain bg-fixed ">
        <div className="w-full flex flex-col justify-center  items-start ">
          <p className="js-show-on-scroll font-['Montserrat'] font-regular text-xl md:text-[2.5rem] leading-[80px]">
            In a world of 8 Billion people, we
          </p>
          <p className="js-show-on-scroll font-['Montserrat'] font-bold text-2xl md:text-[2.5rem] leading-[80px] ">
            SEE YOU
          </p>
          <p className="js-show-on-scroll font-['Montserrat'] font-bold text-2xl md:text-[2.5rem] leading-[80px] ">
            HEAR YOU
          </p>
          <p className="js-show-on-scroll font-['Montserrat'] font-bold text-2xl md:text-[2.5rem] leading-[80px] ">
            CARE FOR YOU
          </p>
          <div className="js-show-on-scroll cursor-pointer mt-[60px] rounded-[10px] h-[65px] flex justify-center items-center w-[240px] bg-[#4A0251]">
            <p className="font-['Montserrat'] font-bold text-[26px] leading-[32px] text-center text-[#ffffff]">
              Join Us
            </p>
          </div>
        </div>
      </div>
      <Element name="About">
        <div className="flex lg:flex-row flex-col min-h-screen items-center justify-center">
          <img src={Sofa} className="js-show-on-scroll" alt="background" />
          <div className="min-h-[32vw] flex flex-col justify-center pl-[24px] pr-[54px]">
            <div className="mb-[2rem] w-max">
              <p className="font-['Montserrat'] font-medium text-[2.25rem] mt-5 leading-[32px]">
                What Is Sofa?
              </p>
              <div className="mt-5 w-[80%] h-[3px] float-right right-0 bg-[#000000]" />
            </div>
            <p className="font-['Montserrat'] font-regular text-[1rem] leading-[27px] text-justify">
              Sofa is an app that partners with you for the betterment of your
              mental health. This is achieved by connecting you to thorougly
              vetted and licensed therapists, as well as granting you access to
              mental health resources available whenever you need them.
            </p>
            <br />
            <p className="font-['Montserrat'] font-regular text-[1rem] leading-[27px] text-justify">
              Your Sofa Pal exists to ensure you are aware that there are people
              out there who care genuinely about your mental health, and are
              keen on ensuring you have easy and affordable access to all you
              need.
            </p>
          </div>
        </div>
      </Element>
      <Element name="Features">
        <div className="bg-[#C487C914]">
          <div className="bg-[#BF03CF0F] w-full min-h-[145px] z-1 absolute " />
          <div className="flex flex-row  w-full justify-between">
            <p className="font-['Montserrat'] font-regular text-[2.25rem] leading-[72px] ml-8 min-h-[145px] pt-[62px]">
              How Does Sofa Work?
            </p>
            <img
              src={FeatBG}
              alt=""
              className="w-[40vw] float-right right-0 z-2"
            />
          </div>
          <div className="mt-5 w-full js-show-on-scroll">
            {/* <div className="m-12 js-show-on-scroll w-[394px] h-[300px]">
              <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251A8] h-[60px] flex justify-center items-center">
                <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                  Speak with a Therapist
                </p>
              </div>
              <div className="rounded-b-[30px] bg-[#451E48] h-[240px] px-4 w-full flex justify-between items-center">
                <p
                  className={`${
                    show ? "block" : "hidden"
                  } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                >
                  Be matched with a professional therapist that can attend to
                  your specific needs. Whether you prefer video/phone calls,
                  text or in-person chats, Sofa Therapy is accessible and
                  affordable.
                </p>

                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } w-[161px]`}
                >
                  <div className="mb-1 mt-8 w-[120px] h-[21px] bg-[#D42CE226] rounded-[10px] rounded-bl-[0px] flex justify-center items-center">
                    <p className="font-extrabold font-['Montserrat'] text-[10px] text-center text-white tracking-[-0.015em]">
                      Therapist Profile
                    </p>
                  </div>

                  <img src={Christina} alt="" />
                </div>
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } w-[184px]`}
                >
                  <p className="font-extrabold font-['Montserrat'] text-[13px]  text-white leading-[21px]">
                    Bio
                  </p>
                  <p className="font-normal font-['Montserrat'] text-[10px] text-white leading-[21px]">
                    Brief bio about the therapist. Written by the therapist, of
                    course.
                  </p>
                  <p className="mt-3 font-extrabold font-['Montserrat'] text-[10px] text-white leading-[21px]">
                    Pick Session:
                  </p>
                  <div className="flex flex-row justify-between items-center">
                    <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                      <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                        Tue, 8am
                      </p>
                    </div>
                    <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                      <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                        Thu, 8am
                      </p>
                    </div>
                    <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                      <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                        Mon, 8am
                      </p>
                    </div>
                  </div>
                  <div className="h-[2px] w-full bg-[#FFFFFF69] drop-shadow-lg my-2 " />
                  <div className="w-[154px] h-8 rounded-[40px] flex justify-center items-center align-center mx-auto bg-[#100521] opacity-30">
                    <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                      Book therapist (₦20,000)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-12 js-show-on-scroll w-[394px] h-[300px]">
              <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251] h-[60px] flex justify-center items-center">
                <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                  Play. Pause. Shuffle. Repeat.
                </p>
              </div>
              <div
                className={`rounded-b-[30px] bg-[#4A0251A8] h-[240px] px-4 w-full ${
                  show && "flex justify-between items-center"
                } `}
              >
                <p
                  className={`${
                    show ? "block" : "hidden"
                  } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                >
                  Scroll through our playlist of exclusive songs, find what best
                  suits your soul, relax in the rhythm and drown out the noise.
                  Sofa Music is healing, one note, one beat, one sound at a
                  time.
                </p>
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } flex flex-row w-full justify-between items-end pt-6`}
                >
                  <div className="">
                    <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
                      You are not alone
                    </p>
                    <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                      Ukpono Akpan
                    </p>
                  </div>
                  <img src={Woman} alt="woman" />
                </div>
                <img
                  src={Scroll}
                  alt="scroll"
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } my-2 w-full`}
                />
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } flex justify-between items-center`}
                >
                  <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                    0:00
                  </p>
                  <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                    2:35
                  </p>
                </div>
                <div
                  className={`flex justify-center items-center transition-all duration-200 ${
                    show ? "hidden" : "block"
                  }`}
                >
                  <img src={Rewind} alt="Rewind" />
                  <img src={Play} alt="Play" className="mx-[27px]" />
                  <img src={Fast} alt="Fast" />
                </div>
              </div>
            </div>
            <div className="m-12 js-show-on-scroll w-[394px] h-[300px]">
              <div className="cursor-pointer rounded-t-[30px] bg-[#7E2D85BD] h-[60px] flex justify-center items-center">
                <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                  Tune into Truth
                </p>
              </div>
              <div
                className={`rounded-b-[30px] bg-[#64046C] h-[240px] px-4 w-full ${
                  show && "flex justify-between items-center"
                }`}
              >
                <p
                  className={`${
                    show ? "block" : "hidden"
                  } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                >
                  Find relatability for your soul. plug into real conversations
                  for some insight, transparency, encouragement, humour and
                  warmth from our hosts & guests on the Sofa Talk.
                </p>
                <div
                  className={`flex flex-row w-full justify-between items-end pt-2 transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } `}
                >
                  <div className="">
                    <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
                      Podcast Name
                    </p>
                    <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                      Season 0, Episode 0
                    </p>
                  </div>
                  <img src={Mic} alt="mic" />
                </div>
                <img
                  src={Scroll}
                  alt="scroll"
                  className={`my-2 w-full transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } `}
                />
                <div
                  className={`flex justify-between items-center transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } `}
                >
                  <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                    0:00
                  </p>
                  <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                    7:05
                  </p>
                </div>
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } flex justify-center items-center`}
                >
                  <img src={Rewind} alt="Rewind" />
                  <img src={Play} alt="Play" className="mx-[27px]" />
                  <img src={Fast} alt="Fast" />
                </div>
              </div>
            </div>
            <div className="m-12 js-show-on-scroll w-[394px] h-[300px]">
              <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251C2] h-[60px] flex justify-center items-center">
                <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                  Write it Out
                </p>
              </div>
              <div
                className={`rounded-b-[30px] bg-[#341250] h-[240px] w-full ${
                  show && "flex justify-between items-center"
                } `}
              >
                <p
                  className={`${
                    show ? "block" : "hidden"
                  } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                >
                  It gets real personal in your Sofa Diary, because you deserve
                  to pour it out; anytime, anywhere. It’s just you and your
                  words – write your heart out. It’s your safe place.
                </p>
                <div
                  className={`px-4 flex flex-row w-full justify-between items-end pt-2 transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } `}
                >
                  <img src={Diary} alt="Diary" />
                  <div className="mx-2">
                    <p className="font-bold font-['Montserrat'] text-[17px] leading-[34px] text-center text-white">
                      Welcome to SofaDiary
                    </p>
                    <div className="bg-[#826DAF66] h-[100px] rounded-[24px] py-[15px] px-2 items-center justify-center flex">
                      <p className="font-medium font-['Montserrat'] text-[9px] leading-[21px] text-center text-white">
                        You deserve to let it all out. No filters, no
                        judgements. Just pour out your thoughts here and let
                        these pages safekeep them.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } mt-5 h-[22px] w-full bg-[#72129F75]`}
                />
                <div
                  className={`transition-all duration-200 ${
                    show ? "hidden" : "block"
                  } h-[22px] w-full bg-[#956AA975] mt-[6px]`}
                />
              </div>
            </div> */}
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 9950,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper flex justify-center items-center flex-1 w-full"
            >
              {Swipers.map((item, index) => (
                <SwiperSlide>
                  <SlideItem item={item} key={index} />
                </SwiperSlide>
              ))}
              {/* <SwiperSlide>
                <div className="w-full justify-center items-center flex">
                  <div className="mb-12 w-[80%] lg:w-[420px] h-[300px]">
                    <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251A8] h-[60px] flex justify-center items-center">
                      <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                        Speak with a Therapist
                      </p>
                    </div>
                    <div className="rounded-b-[30px] bg-[#451E48] h-[240px] px-4 w-full flex justify-between items-center">
                      <p
                        className={`${
                          show ? "block" : "hidden"
                        } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                      >
                        Be matched with a professional therapist that can attend
                        to your specific needs. Whether you prefer video/phone
                        calls, text or in-person chats, Sofa Therapy is
                        accessible and affordable.
                      </p>

                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } w-[161px]`}
                      >
                        <div className="mb-1 mt-8 w-[120px] h-[21px] bg-[#D42CE226] rounded-[10px] rounded-bl-[0px] flex justify-center items-center">
                          <p className="font-extrabold font-['Montserrat'] text-[10px] text-center text-white tracking-[-0.015em]">
                            Therapist Profile
                          </p>
                        </div>

                        <img src={Christina} alt="" />
                      </div>
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } w-[184px]`}
                      >
                        <p className="font-extrabold font-['Montserrat'] text-[13px]  text-white leading-[21px]">
                          Bio
                        </p>
                        <p className="font-normal font-['Montserrat'] text-[10px] text-white leading-[21px]">
                          Brief bio about the therapist. Written by the
                          therapist, of course.
                        </p>
                        <p className="mt-3 font-extrabold font-['Montserrat'] text-[10px] text-white leading-[21px]">
                          Pick Session:
                        </p>
                        <div className="flex flex-row justify-between items-center">
                          <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                            <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                              Tue, 8am
                            </p>
                          </div>
                          <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                            <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                              Thu, 8am
                            </p>
                          </div>
                          <div className="w-[58px] h-9 rounded-[60px] flex justify-center items-center bg-[#0000004A]">
                            <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                              Mon, 8am
                            </p>
                          </div>
                        </div>
                        <div className="h-[2px] w-full bg-[#FFFFFF69] drop-shadow-lg my-2 " />
                        <div className="w-[154px] h-8 rounded-[40px] flex justify-center items-center align-center mx-auto bg-[#100521] opacity-30">
                          <p className="font-normal font-['Montserrat'] text-[9px] text-white leading-[21px]">
                            Book therapist (₦20,000)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full justify-center items-center flex">
                  <div className=" mb-12 w-[80%] lg:w-[420px] h-[300px]">
                    <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251] h-[60px] flex justify-center items-center">
                      <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                        Play. Pause. Shuffle. Repeat.
                      </p>
                    </div>
                    <div
                      className={`rounded-b-[30px] bg-[#4A0251A8] h-[240px] px-4 w-full ${
                        show && "flex justify-between items-center"
                      } `}
                    >
                      <p
                        className={`${
                          show ? "block" : "hidden"
                        } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                      >
                        Scroll through our playlist of exclusive songs, find
                        what best suits your soul, relax in the rhythm and drown
                        out the noise. Sofa Music is healing, one note, one
                        beat, one sound at a time.
                      </p>
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } flex flex-row w-full justify-between items-end pt-6`}
                      >
                        <div className="">
                          <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
                            You are not alone
                          </p>
                          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                            Ukpono Akpan
                          </p>
                        </div>
                        <img src={Woman} alt="woman" />
                      </div>
                      <img
                        src={Scroll}
                        alt="scroll"
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } my-2 w-full`}
                      />
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } flex justify-between items-center`}
                      >
                        <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                          0:00
                        </p>
                        <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                          2:35
                        </p>
                      </div>
                      <div
                        className={`flex justify-center items-center transition-all duration-200 ${
                          show ? "hidden" : "block"
                        }`}
                      >
                        <img src={Rewind} alt="Rewind" />
                        <img src={Play} alt="Play" className="mx-[27px]" />
                        <img src={Fast} alt="Fast" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full justify-center items-center flex">
                  <div className=" mb-12 w-[80%] lg:w-[420px] h-[300px]">
                    <div className="cursor-pointer rounded-t-[30px] bg-[#7E2D85BD] h-[60px] flex justify-center items-center">
                      <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                        Tune into Truth
                      </p>
                    </div>
                    <div
                      className={`rounded-b-[30px] bg-[#64046C] h-[240px] px-4 w-full ${
                        show && "flex justify-between items-center"
                      }`}
                    >
                      <p
                        className={`${
                          show ? "block" : "hidden"
                        } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                      >
                        Find relatability for your soul. plug into real
                        conversations for some insight, transparency,
                        encouragement, humour and warmth from our hosts & guests
                        on the Sofa Talk.
                      </p>
                      <div
                        className={`flex flex-row w-full justify-between items-end pt-2 transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } `}
                      >
                        <div className="">
                          <p className="font-bold font-['Montserrat'] text-[14px] leading-[27px] drop-shadow-sm text-white mb-2">
                            Podcast Name
                          </p>
                          <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                            Season 0, Episode 0
                          </p>
                        </div>
                        <img src={Mic} alt="mic" />
                      </div>
                      <img
                        src={Scroll}
                        alt="scroll"
                        className={`my-2 w-full transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } `}
                      />
                      <div
                        className={`flex justify-between items-center transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } `}
                      >
                        <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                          0:00
                        </p>
                        <p className="font-normal font-['Montserrat'] text-[10px] leading-[21px] text-white">
                          7:05
                        </p>
                      </div>
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } flex justify-center items-center`}
                      >
                        <img src={Rewind} alt="Rewind" />
                        <img src={Play} alt="Play" className="mx-[27px]" />
                        <img src={Fast} alt="Fast" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full justify-center items-center flex">
                  <div className=" mb-12 w-[80%] lg:w-[420px] h-[300px]">
                    <div className="cursor-pointer rounded-t-[30px] bg-[#4A0251C2] h-[60px] flex justify-center items-center">
                      <p className="font-extrabold font-['Montserrat']text-[24px] leading-[36px] text-center text-white tracking-[-0.015em]">
                        Write it Out
                      </p>
                    </div>
                    <div
                      className={`rounded-b-[30px] bg-[#341250] h-[240px] w-full ${
                        show && "flex justify-between items-center"
                      } `}
                    >
                      <p
                        className={`${
                          show ? "block" : "hidden"
                        } transition-all duration-200 font-normal font-['Montserrat'] text-[14px] p-2 leading-[21px] text-center text-white`}
                      >
                        It gets real personal in your Sofa Diary, because you
                        deserve to pour it out; anytime, anywhere. It’s just you
                        and your words – write your heart out. It’s your safe
                        place.
                      </p>
                      <div
                        className={`px-4 flex flex-row w-full justify-between items-end pt-2 transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } `}
                      >
                        <img src={Diary} alt="Diary" />
                        <div className="mx-2">
                          <p className="font-bold font-['Montserrat'] text-[17px] leading-[34px] text-center text-white">
                            Welcome to SofaDiary
                          </p>
                          <div className="bg-[#826DAF66] h-[100px] rounded-[24px] py-[15px] px-2 items-center justify-center flex">
                            <p className="font-medium font-['Montserrat'] text-[9px] leading-[21px] text-center text-white">
                              You deserve to let it all out. No filters, no
                              judgements. Just pour out your thoughts here and
                              let these pages safekeep them.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } mt-5 h-[22px] w-full bg-[#72129F75]`}
                      />
                      <div
                        className={`transition-all duration-200 ${
                          show ? "hidden" : "block"
                        } h-[22px] w-full bg-[#956AA975] mt-[6px]`}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </Element>
      <Element name="Join">
        <div className="w-full min-h-screen mt-[64px] flex flex-col items-center justify-between py-3">
          <p className="font-['Montserrat'] font-regular text-[2.25rem] leading-[50px] text-center mb-[50px]">
            Now you know about us
          </p>
          <div className="absolute my-28 w-[80%] lg:w-[43%]  rounded-[20px] drop-shadow-md  border border-[#00000026] bg-[#FFFFFF] js-show-on-scroll">
            <div className="bg-[#CD6ED680] mt-[64px] px-8 lg:px-[64px] py-[10px] flex items-center">
              <p className="font-['Montserrat'] font-bold text-[20px] leading-[36px] ">
                Tell us about yourself
              </p>
            </div>
            <div className="p-8 lg:p-[64px]">
              <div className="flex flex-row flex-wrap justify-between">
                <input
                  placeholder="First Name"
                  className="px-[15px] my-3 h-[40px] lg:w-[48%] w-full rounded-[20px] border border-[#00000026]"
                />
                <input
                  placeholder="Last Name"
                  className="px-[15px] my-3 h-[40px] lg:w-[48%] w-full rounded-[20px] border border-[#00000026]"
                />
              </div>
              <input
                placeholder="Email Address"
                className="px-[15px] my-3 h-[40px] w-full rounded-[20px] border border-[#00000026]"
              />
              <input
                placeholder="Gender"
                className="px-[15px] my-3 h-[40px] w-full rounded-[20px] border border-[#00000026]"
              />
              <div className="cursor-pointer my-3 rounded-[10px] h-[40px] flex justify-center items-center w-[130px] bg-[#4A0251]">
                <p className=" font-['Montserrat'] font-bold text-[15px] leading-[16px] text-center text-[#ffffff]">
                  Join Us
                </p>
              </div>
            </div>
          </div>
          <img
            src={ContactBG}
            alt={"Background"}
            className="w-screen z-[-1] "
          />
        </div>
      </Element>
      <Element name="FAQ">
        <div className="px-8">
          <p className="font-['Montserrat'] font-regular text-[2.25rem] leading-[72px] mb-[40px]">
            FAQ
          </p>
          <div className="flex flex-wrap justify-between">
            <div className="md:w-[45%]">
              {faqData.map((item, index) => (
                <FaqItem
                  header={item.header}
                  details={item.details}
                  key={index}
                />
              ))}
            </div>
            <div className="md:w-[45%]">
              {faqData2.map((item, index) => (
                <FaqItem
                  header={item.header}
                  details={item.details}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      </Element>
      <div className="w-[95%] mx-auto h-[1px] bg-[#00000080]  mb-9" />
      <div className="px-8 flex flex-wrap-reverse items-center justify-center lg:justify-between">
        <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[24px] my-3">
          © Sofa, Inc. 2022. Your mental health comes first!
        </p>
        <div className="flex flex-row items-center justify-between">
          <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[24px]">
            Follow us:
          </p>
          {/* <img className="mx-2 cursor-pointer" alt="social" src={Telegram} /> */}
          <a href="https://www.instagram.com/sofa.africa/">
            <img className="mx-2 cursor-pointer" alt="social" src={Instagram} />
          </a>
          <a href="https://twitter.com/SofaAfrica">
            <img className="mx-2 cursor-pointer" alt="social" src={Twitter} />
          </a>
          {/* <img className="mx-2 cursor-pointer" alt="social" src={Facebook} /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
