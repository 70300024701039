import { Link } from "react-scroll";

/* eslint-disable */
export const faqData = [
  {
    header: "How do I get started?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        You can get started by{" "}
        <span className="cursor-pointer text-[#4A0251] font-bold">
          <Link spy={true} smooth={true} duration={500} to="Join">
            joining us
          </Link>
        </span>
        , so that you can be notified when the Sofa app launches. The onboarding
        process is easy; you will answer a few questions which will help us know
        you and any preferences you have for Sofa Therapy. Based on these
        answers, Ted (your Sofa Pal) will suggest potential therapists you can
        choose from, and help you navigate the array of music and podcasts
        available.
      </p>
    ),
  },
  {
    header: "I’m a licensed therapist, how can I apply to be a part of sofa?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        Thanks for your interest!
        <a
          className="text-[#4A0251] font-bold"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfT3aDhTIYIHmtNoptFNS6a6CozYJagF7h4_kVQnyJCi-qAJw/viewform?usp=sf_link"
        >
          {" "}
          Click here{" "}
        </a>
        to register.
      </p>
    ),
  },
  {
    header: "How much does Sofa cost?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        An online session of Sofa Therapy costs ₦10,000, which comes with being
        able to talk with your therapist for 3 days. An in-person session costs
        ₦15,000. Sofa Talk and Sofa Music are charged in a bundle of ₦900/month.
        Sofa Diary is free.
      </p>
    ),
  },
  {
    header: "Can I gaurantee that my privacy is protected?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        *Insert reply*
      </p>
    ),
  },
];

export const faqData2 = [
  {
    header: "How can I get across to my therapist?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        Whichever way is most convenient for you. Therapists are available for
        in-person chats, in-app (video/voice) calls, and in-app texts, depending
        on the plan you go for. You don’t have to wait till your next scheduled
        session to interact with your therapist.{" "}
        <span className="cursor-pointer text-[#4A0251] font-bold">
          <Link spy={true} smooth={true} duration={500} to="Join">
            {" "}
            Join us{" "}
          </Link>
        </span>
        to know more
      </p>
    ),
  },
  {
    header: "Are the therapists qualified?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        Yes, very much. Each therapist on our platform is licensed and has gone
        through a rigorous process of us testing their professional abilities,
        not just on paper, but practically as well. They are also trained on
        maximising the features of the app to ensure you get the best
        experience.
      </p>
    ),
  },
  {
    header: `I\'m interested in face - to - face therapy sessions. Where are
  the therapists located ?`,
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        We have therapists in Lagos,Abuja,... We hope to keep expanding our
        reach to as many cities in Africa as possible.
      </p>
    ),
  },
  {
    header: "Is there a free version ?",
    details: (
      <p className="font-['Montserrat'] text-justify font-regular text-[1rem] leading-[30px] mb-[20px]">
        There’s a 7-day free trial version for Sofa Talk and Sofa Music.
        However, there is no free trial version for Sofa Therapy.
      </p>
    ),
  },
];
